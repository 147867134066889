"use client";

import React, { useEffect, useState } from "react";
import OpenInSystemBrowserButton from "./OpenInSystemBrowserButton";
import { ClientSafeProvider } from "next-auth/react";
import Agreements from "./Agreements";
import GoogleSignInButton from "./GoogleSignInButton";
import AlertOpenInSystemBrowser from "./AlertOpenInSystemBrowser";

interface Props {
  providers: Record<string, ClientSafeProvider> | null;
}

export default function SignInButton({ providers }: Props) {
  const [forceExternal, setForceExternal] = useState<boolean>(false);
  const [externalBrowserButtonNeeded, setExternalBrowserButtonNeeded] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const wnd = window as any;
    const ua = navigator.userAgent || navigator.vendor || wnd.opera;
    if (ua.includes("KAKAOTALK") | ua.includes("Line")) {
      setExternalBrowserButtonNeeded(true);
    }
    if (
      ua.indexOf("FBAN") >= 0 ||
      ua.indexOf("FBAV") >= 0 ||
      ua.indexOf("Instagram") >= 0
    ) {
      setForceExternal(true);
    }
    setIsLoading(false);
  }, []);

  if (isLoading) return <></>;

  //if (externalBrowserButtonNeeded) return <OpenInSystemBrowserButton />;
  //if (forceExternal) return <AlertOpenInSystemBrowser />;

  return (
    <div className="flex flex-col items-center justify-center gap-3">
      {providers && providers?.google && <GoogleSignInButton />}
      <Agreements />
    </div>
  );
}
