import React from "react";
import Button from "../../buttons/Button";

interface Props {
  title: string;
  onClick: () => void;
  type: "positive" | "negative";
  disabled?: boolean;
}

export const buttonType = Object.freeze({
  positive: "positive",
  negative: "negative",
});

export default function ModalButton({
  title,
  onClick,
  type,
  disabled = false,
}: Props) {
  const isPositiveButton = type === buttonType.positive;
  return (
    <Button
      title={title}
      onClick={onClick}
      disabled={disabled}
      style={
        isPositiveButton
          ? `${
              disabled
                ? "bg-neutral-400 text-gray-200 hover:bg-gray-300"
                : "bg-primaryColor hover:bg-violet-300"
            }`
          : "bg-backgroundColor hover:bg-[#a7ad9c]"
      }
    />
  );
}
