"use client";

import React from "react";
import ModalBase from "../../common/modals/layout/ModalBase";

interface ClosedAccountModalProps {
  onClose: () => void;
}

export default function ClosedAccountModal({
  onClose,
}: ClosedAccountModalProps) {
  return (
    <ModalBase title="Closed Account" onClose={onClose}>
      <p>
        {`This account has been closed by the user. All information was deleted and you can't sign in with this account again.`}
      </p>
      <p className="my-4">
        To sign up again with this account, you need to contact us by{" "}
        <a
          href="mailto:contact@tooeasy.life"
          className="font-semibold underline"
        >
          email
        </a>{" "}
        personally.
      </p>
    </ModalBase>
  );
}
