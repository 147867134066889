"use client";

import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Play } from 'lucide-react';

// 비디오 데이터 타입 정의
interface VideoData {
  videoUrl: string;
  thumbnailUrl: string;
}

// 비디오 데이터 배열
const VIDEOS: VideoData[] = [
  {
    videoUrl: "https://sopi-content-prod.s3.ap-northeast-2.amazonaws.com/media_files/final_video_20241201_085429_e13b7ad4-cb63-4205-8f46-8f5a516ec2df.mp4",
    thumbnailUrl: "https://sopi-content-prod.s3.ap-northeast-2.amazonaws.com/thumbnail/thumbnail_20241201_084710_d12605c5-6fae-461b-b9aa-26a9689a3fc0.jpg"
  },
  {
    videoUrl: "https://sopi-content-prod.s3.ap-northeast-2.amazonaws.com/media_files/final_video_20241202_174747_f02e0006-e518-4755-ae79-29bf02671eca.mp4",
    thumbnailUrl: "https://sopi-content-prod.s3.ap-northeast-2.amazonaws.com/thumbnail/thumbnail_20241202_174022_2da798f4-1d39-4e53-8626-3c9367765bee.jpg"
  },
  {
    videoUrl: "https://sopi-content-prod.s3.ap-northeast-2.amazonaws.com/media_files/final_video_20241203_192830_5292ddf5-b625-450c-a293-a848277fd595.mp4",
    thumbnailUrl: "https://sopi-content-prod.s3.ap-northeast-2.amazonaws.com/thumbnail/thumbnail_20241203_191718_98e5ee45-bc9b-4b3e-972d-043d717b2934.jpg"
  },
  {
    videoUrl: "https://sopi-content-prod.s3.ap-northeast-2.amazonaws.com/media_files/dance_video20241120_034917_158bb3fb-c022-4c3f-94dc-e7ba8d21d0f7.mp4",
    thumbnailUrl: "https://sopi-content-prod.s3.ap-northeast-2.amazonaws.com/thumbnail/thumbnail_20241120_033848_3517774a-03b5-4966-af46-a6c01c70cf76.jpg"
  },
  {
    videoUrl: "https://sopi-content-prod.s3.ap-northeast-2.amazonaws.com/media_files/YkL-zqPlE3hejd54e8_8h_final_output_final.mp4",
    thumbnailUrl: "https://sopi-content-prod.s3.ap-northeast-2.amazonaws.com/thumbnail/fDbNBRDD7YHwC0YnarP0d_41ee37829dc644b48b8a7f1d40619360.jpg"
  },
];

export default function VideoSection() {
  const [activeVideo, setActiveVideo] = useState<number | null>(null);
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const [isLargeScreen, setIsLargeScreen] = useState<boolean | null>(null);


  useEffect(() => {
  if (typeof window !== "undefined") {
    videoRefs.current.forEach((video) => {
      if (video) {
        video.muted = false;
        video.autoplay = true;
        video.loop = true;
        video.play().catch((error) => {
          console.error("자동 재생 실패:", error.message);
        });
      }
    });
  }
  }, []);

    useEffect(() => {
      if (typeof window !== "undefined") {
        setIsLargeScreen(window.innerWidth >= 768);
        const handleResize = () => {
          setIsLargeScreen(window.innerWidth >= 768);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }
    }, []);


    const handleVideoPlay = (index: number) => {
    // 이전에 재생 중인 영상 멈추기
    if (activeVideo !== null) {
      videoRefs.current[activeVideo]?.pause();
    }

      // 클릭한 영상 설정
    setActiveVideo(index);

    const videoElement = videoRefs.current[index];

    if (videoElement) {
          if (window.innerWidth <= 768) {
            // 모바일: 풀스크린 요청
            videoElement.muted = false; // 음소거 해제
            videoElement.play();
            videoElement.requestFullscreen?.();
            videoElement.style.objectFit = "contain";
          } else {
            // PC: 음소거 해제 후 재생
            videoElement.muted = false;
            videoElement.play();
          }
      }
    };

  return (
    <section className="w-full bg-black py-16 overflow-hidden">
      <div className="max-w-full w-full px-4 sm:max-w-6xl sm:mx-auto">
        <h2 className="text-3xl font-bold text-white text-center mb-12">
          어떤 아이디어도 숏폼으로
        </h2>

        <div className="w-full overflow-hidden">
          <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              slidesPerView="auto"
              spaceBetween={15}
              navigation={{
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
                disabledClass: 'opacity-30',
              }}
              pagination={{
                clickable: true,
                bulletClass: 'swiper-pagination-bullet bg-gray-500',
                bulletActiveClass: 'swiper-pagination-bullet-active !bg-white',
              }}
              breakpoints={{
                320: {
                  spaceBetween: 10, // 모바일에서 슬라이드 간 간격
                },
                640: {
                  spaceBetween: 15,
                },
                1024: {
                  spaceBetween: 20,
                },
              }}
              className="video-swiper relative w-full"
            >
            <div className="swiper-button-prev !text-white absolute top-1/2 left-0 z-10 -translate-y-1/2 cursor-pointer"></div>
            <div className="swiper-button-next !text-white absolute top-1/2 right-0 z-10 -translate-y-1/2 cursor-pointer"></div>

            {VIDEOS.map((video, index) => (
                <SwiperSlide
                      key={index}
                      className="flex justify-center"
                      style={{
                      flex: typeof window !== "undefined" && isLargeScreen
                        ? "0 0 calc(100% / 3 - 15px)"
                        : "0 0 calc(100% / 2 - 15px)",
                    }}
                    >
                <div
                  className="relative w-full aspect-[9/16] rounded-2xl overflow-hidden group shadow-2xl transition-all duration-300"
                  onClick={() => handleVideoPlay(index)}
                >
                  {activeVideo === index ? (
                    <video
                      ref={(el) => {
                        videoRefs.current[index] = el;
                      }}
                      src={video.videoUrl}
                      className="absolute inset-0 w-full h-full object-cover"
                      playsInline // 모바일 대응
                      autoPlay // 자동 재생
                      loop // 반복 재생
                      controls
                    />
                  ) : (
                    <div className="relative w-full h-full group cursor-pointer">
                      <img
                        src={video.thumbnailUrl}
                        alt={`Video ${index + 1}`}
                        className="absolute inset-0 w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-20 flex items-center justify-center">
                        <Play
                          className="w-15 h-15 transform transition-transform group-hover:scale-110 drop-shadow-md
                                     text-white/80 group-hover:text-[#7BD706] fill-white/50 group-hover:fill-[#7BD706]"
                          strokeWidth={1}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}