export type EventType =
  | "signin_click"
  | "signup_complete"
  | "signin_complete"
  | "seemore_show"
  | "segmentgroup_tab_click"
  | "journey_tab_click"
  | "topics_tab_click"
  | "segments_click"
  | "topics_click"
  | "startchat_click"
  | "previoussession_click"
  | "heart_click"
  | "settings_click"
  | "recommendedresponses_click"
  | "send_click"
  | "backtomain_click"
  | "invite_click"
  | "invitelink_click"
  | "subscription_icon_click"
  | "subscription_try_click"
  | "subscription_complete"
  | "download_app_click";

export interface EventProps {
  action: EventType;
  value?: string;
}

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_ID;

export const pageview = (url: string) => {
  if (!GA_TRACKING_ID) return;
  window.gtag("config", GA_TRACKING_ID, {
    page_path: url,
  });
};

export const logGaEvent = ({ action, value }: EventProps) => {
  const isDebug = process.env.NODE_ENV === "development";
  if (isDebug) {
    console.log({
      action,
      value,
    });
    return;
  }
  window.gtag("event", action, {
    value: value,
  });
};
