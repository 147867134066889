import React, { MouseEvent, useEffect } from "react";
import ModalButton, { buttonType } from "./ModalButton";
import ModalCloseButton from "./ModalCloseButton";
import ModalPortal from "./ModalPortal";

interface Props {
  children: React.ReactNode;
  onClose: () => void;
  title: string;
  positiveButtonTitle?: string;
  onPositiveButtonClick?: () => void;
  positiveButtonDisabled?: boolean;
  negativeButtonTitle?: string;
  onNegativeButtonClick?: () => void;
}

export default function ModalBase({
  children,
  onClose,
  title,
  positiveButtonTitle,
  onPositiveButtonClick,
  positiveButtonDisabled,
  negativeButtonTitle,
  onNegativeButtonClick,
}: Props) {
  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onClose]);

  const handleBackgroundClick = (e: MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <ModalPortal>
      <div
        className="absolute left-0 top-0 z-50 flex h-full w-full flex-col items-center justify-center bg-zinc-400/20"
        onClick={handleBackgroundClick}
      >
        <div className="relative flex w-[85%] flex-col items-center rounded-lg border-[0.8px] border-textColor bg-white px-7 pb-9 pt-12 text-center shadow-md sm:w-[350px]">
          <ModalCloseButton onClick={onClose} />
          <h3 className="text-xl font-extrabold">{title}</h3>
          <div className="w-full whitespace-pre-wrap px-1 pt-5 text-lg font-light">
            {children}
          </div>
          {(positiveButtonTitle || negativeButtonTitle) && (
            <div className="flex gap-3 pt-6">
              {negativeButtonTitle && onNegativeButtonClick && (
                <ModalButton
                  title={negativeButtonTitle}
                  onClick={onNegativeButtonClick}
                  type={buttonType.negative}
                />
              )}
              {positiveButtonTitle && onPositiveButtonClick && (
                <ModalButton
                  title={positiveButtonTitle}
                  onClick={onPositiveButtonClick}
                  type={buttonType.positive}
                  disabled={positiveButtonDisabled}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </ModalPortal>
  );
}
