import React from "react";
import { AiOutlineClose } from "react-icons/ai";

interface Props {
  onClick: () => void;
}

export default function ModalCloseButton({ onClick }: Props) {
  return (
    <button
      className="w-7 h-7 rounded-md flex items-center justify-center bg-transparent hover:bg-darkGray absolute right-5 top-4"
      onClick={onClick}
    >
      <AiOutlineClose className="w-5 h-5" />
    </button>
  );
}
