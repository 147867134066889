"use client";

import ClosedAccountModal from "@/components/domain/auth/ClosedAccountModal";
import useErrorModal from "@/hooks/useErrorModal";
import { signIn, signOut, useSession } from "next-auth/react";
import { useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { logGaEvent } from "../../../service/gtag";
import { Download } from "lucide-react"; // 아이콘 추가를 위해 lucide-react 사용 권장

const APPSTORE_LINKS = {
    'ios': 'https://apps.apple.com/kr/app/id6738605322',
    'aos': 'https://play.google.com/store/apps/details?id=life.tooeasy.clonef',
};

const detectPlatform = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (/iphone|ipad|ipod/.test(userAgent)) {
        return 'ios';
    } else if (/android/.test(userAgent)) {
        return 'aos';
    } else {
        return 'ios';
    }
};

export default function AppDownloadButton() {
    const [platform, setPlatform] = useState<string | null>(null);

    useEffect(() => {
        setPlatform(detectPlatform());
    }, []);

    const handleDownload = () => {
        logGaEvent({ action: "download_app_click" });

        if (platform) {
            window.location.href = APPSTORE_LINKS[platform as keyof typeof APPSTORE_LINKS];
        }
    };

    return (
        <button
            className="
                relative
                w-full
                max-w-[320px]
                py-4
                px-6
                rounded-xl
                bg-gradient-to-r
                from-[#2c2c2c]
                to-[#1a1a1a]
                text-white
                font-bold
                text-lg
                tracking-wider
                border
                border-opacity-20
                border-white
                shadow-2xl
                transform
                transition-all
                duration-300
                hover:scale-[1.05]
                active:scale-[0.95]
                focus:outline-none
                focus:ring-2
                focus:ring-white
                focus:ring-opacity-50
                flex
                items-center
                justify-center
                gap-3
                overflow-hidden
            "
            onClick={handleDownload}
        >
            <div className="absolute inset-0 bg-white bg-opacity-10 hover:bg-opacity-20 transition-all duration-300"></div>
            <Download className="w-6 h-6" />
            앱 다운로드
            <span className="
                absolute
                bottom-0
                left-0
                right-0
                h-1
                bg-gradient-to-r
                from-blue-500
                to-purple-500
            "></span>
        </button>
    );
}
