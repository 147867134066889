import React from "react";
import { Sparkles } from "lucide-react";
import AiTherapistDescItem from "./AiTherapistDescItem";
import { motion } from "framer-motion";

export default function AiTherapistDesc() {
  return (
    <div className="relative overflow-hidden rounded-[2.5rem] bg-black/40 p-8 sm:p-16 backdrop-blur-lg">
      {/* Gradient border effect */}
      <div className="absolute inset-0 bg-gradient-to-b from-white/10 to-white/5 pointer-events-none" />
      <div className="absolute inset-0 bg-gradient-to-r from-violet-500/10 via-transparent to-blue-500/10" />

      {/* Subtle grid background */}
      <div className="absolute inset-0 bg-grid-white/[0.02]" />

      {/* Animated gradient orbs */}
      <div className="absolute -left-20 -top-20 h-[300px] w-[300px] rounded-full bg-violet-500/20 blur-[100px] animate-pulse" />
      <div className="absolute -right-20 -bottom-20 h-[300px] w-[300px] rounded-full bg-blue-500/20 blur-[100px] animate-pulse" />

      <div className="relative">
        <h1 className="relative mb-12 text-3xl font-bold text-white sm:text-5xl bg-clip-text">
          프리미엄 AI 기술
          <Sparkles className="ml-2 inline h-8 w-8 text-yellow-400/90" />
        </h1>

        <div className="grid gap-8 md:grid-cols-2">
          <AiTherapistDescItem
            title="실시간 캐릭터 생성"
            description={[
              "최신 생성형 AI 기술로 사진 한 장만으로 자연스러운 캐릭터를 즉시 생성합니다.",
              "당신이 상상하는 모든 이미지와 영상에서 캐릭터의 표현력을 경험해보세요."
            ]}
            gradient="from-blue-500/80 to-violet-500/80"
          />
          <AiTherapistDescItem
            title="고품질 음성 클로닝"
            description={[
              "목소리로 구성된 유튜브 링크 하나만으로 자연스러운 AI 보이스를 만듭니다.",
              "자연스러운 말투뿐만 아니라, 노래까지 소화할 수 있어요."
            ]}
            gradient="from-violet-500/80 to-fuchsia-500/80"
          />
          <AiTherapistDescItem
            title="전문가급 콘텐츠 생성"
            description={[
              "바이럴 마케팅 전문가들의 노하우를 학습한 AI가 트렌디한 숏폼 콘텐츠를 제작합니다.",
              "섬세한 장면 연출, BGM 작사/작곡, 자막 스타일까지 모두 자동으로 최적화됩니다."
            ]}
            gradient="from-fuchsia-500/80 to-rose-500/80"
          />
          <AiTherapistDescItem
            title="무제한 커스터마이징"
            description={[
              "의상, 헤어스타일, 메이크업부터 배경, 특수효과까지 원하는대로 설정 가능.",
              "매일 새로운 콘셉트의 영상을 제작할 수 있습니다."
            ]}
            gradient="from-blue-500/80 to-cyan-500/80"
          />
        </div>
      </div>
    </div>
  );
}