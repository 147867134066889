import React from "react";
import ChatBubble from "../chat/ChatBubble";

export default function ChatExample() {
  return (
    <div className="relative rounded-3xl bg-black/40 p-8 sm:p-12 backdrop-blur-sm">
      <div className="flex flex-col gap-6">
        <h2 className="text-2xl font-bold text-white sm:text-4xl">
          한 문장으로 시작되는 무한한 이야기
        </h2>
        <div className="space-y-4">
          <div className="flex justify-end">
            <div className="rounded-2xl rounded-tr-none bg-violet-600 px-4 py-3 text-white">
              재테크 관련 상식을, 초등학생도 이해하기 쉽고 재밌게 뮤비 형태로 만들어줘.
            </div>
          </div>
          <div className="flex justify-start">
            <div className="rounded-2xl rounded-tl-none bg-gray-800 px-4 py-3 text-white max-w-2xl">
              수백만 조회 영상을 분석하고, 프로페셔널한 스토리 음악과 뮤비를 만들겠습니다.
              15분 뒤 푸쉬 알림으로 알려드릴게요.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}