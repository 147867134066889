import React from "react";
import ConsultationTechniqueItem from "./ConsultationTechniqueItem";
import { Mail } from "lucide-react";

export default function ConsultationTechniques() {
  return (
    <div className="space-y-12">
      <div className="text-center">
        <h1 className="text-3xl font-bold text-white sm:text-5xl">
          무한한 창작의 가능성
        </h1>
        <p className="mt-4 text-gray-300 sm:text-xl">
          한계없는 당신의 상상력. AI 부캐와 영향력을 펼쳐보세요.
        </p>
      </div>

      <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {capabilities.map((item, index) => (
          <ConsultationTechniqueItem
            key={index}
            title={item.title}
            description={item.description}
            className="group relative overflow-hidden rounded-2xl bg-gray-900 p-6 transition-all hover:bg-gray-800"
          />
        ))}
        {/* Contact Card */}
        <a
          href="mailto:contact@tooeasy.life"
          className="group relative overflow-hidden rounded-2xl bg-gradient-to-br from-violet-600/20 to-blue-600/20 p-6 transition-all hover:from-violet-600/30 hover:to-blue-600/30"
        >
          <div className="absolute inset-0 bg-gray-900 opacity-60" />
          <div className="relative flex flex-col items-center justify-center h-full space-y-4 text-center">
            <h3 className="text-xl font-bold text-white">커스텀 콘텐츠 자동화</h3>
            <Mail className="w-8 h-8 text-gray-300 group-hover:text-white transition-colors" />
            <p className="text-gray-300 group-hover:text-white transition-colors">
              Contact us
            </p>
          </div>
        </a>
      </div>
    </div>
  );
}

const capabilities = [
  {
    title: "스토리텔링",
    description: "전문 작가 수준의 스토리텔링으로 매력적인 브이로그, 숏드라마, 리뷰 등을 제작합니다. • 예시: 여행 브이로그, 일상 모먼트, 제품 리뷰"
  },
  {
    title: "음악 퍼포먼스",
    description: "당신만의 AI 캐릭터가 노래하고 춤추는 모습을 감상해보세요. 커버곡부터 창작곡까지 가능합니다. • 예시: 커버송, 댄스 커버, 뮤직비디오"
  },
  {
    title: "교육 콘텐츠",
    description: "복잡한 주제도 쉽고 재미있게 설명하는 교육 콘텐츠를 제작할 수 있습니다. • 예시: How-to 가이드, 학습 콘텐츠, 전문 지식 공유"
  },
  {
    title: "엔터테인먼트",
    description: "재미있는 상황극, 코미디, 챌린지 등 다양한 엔터테인먼트 콘텐츠를 만들어보세요. • 예시: 챌린지, 코미디, 트렌드 패러디"
  },
  {
    title: "브랜딩",
    description: "브랜드 아이덴티티에 맞는 전문적인 홍보 콘텐츠를 제작합니다. • 예시: 브랜드 홍보, 제품 소개, 기업 문화"
  }
];