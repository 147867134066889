"use client";

import React, { useEffect, useRef, useState } from "react";

import { Element } from "react-scroll";
import { logGaEvent } from "@/service/gtag";
import IntroductionTitle from "@/components/domain/introduction/IntroductionTitle";
import ChatExample from "@/components/domain/introduction/ChatExample";
import Advantages from "@/components/domain/introduction/Advantages";
import AiTherapistDesc from "@/components/domain/introduction/AiTherapistDesc";
import ConsultationTechniques from "@/components/domain/introduction/ConsultationTechniques";

export default function IntroductionPage() {
  const ref = useRef<HTMLDivElement>(null);
  const [isExposed, setIsExposed] = useState(false);

  const handleScroll = () => {
    const targetElement = ref.current;
    if (targetElement) {
      const elementTop = targetElement.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsExposed(elementTop < windowHeight);
    }
  };

  useEffect(() => {
    if (isExposed)
      logGaEvent({
        action: "seemore_show",
      });
  }, [isExposed]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div ref={ref}>
    </div>
  );
}
