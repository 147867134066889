import React from "react";
import Link from "next/link";

export default function Agreements() {
  return (
    <div className="text-center text-[10px] tracking-tight font-light text-textLightColor sm:text-sm sm:tracking-normal">
       {" "}
      <Link className="underline" target="_blank" href="/terms.html">

      </Link>{" "}
       {" "}
      <Link className="underline" target="_blank" href="/privacy.html">

      </Link>

    </div>
  );
}
