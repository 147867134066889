import { atom, useRecoilState } from "recoil";
import { useCallback } from "react";

const errorModalShowedState = atom<boolean>({
  key: "errorModalShowed",
  default: false,
});

export default function useErrorModal() {
  const [errorModalShowed, setErrorModalShowed] = useRecoilState(
    errorModalShowedState
  );
  const showErrorModal = useCallback(() => {
    setErrorModalShowed(true);
  }, [setErrorModalShowed]);
  const closeErrorModal = useCallback(() => {
    setErrorModalShowed(false);
  }, [setErrorModalShowed]);
  return { errorModalShowed, showErrorModal, closeErrorModal };
}
