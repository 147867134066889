import Image from "next/image";
import React from "react";
import sopi from "../../../../public/statics/images/sopi.png";

export default function IntroductionTitle() {
  return (
    <div className="relative overflow-hidden rounded-3xl bg-gradient-to-br from-violet-900 via-slate-900 to-black p-8 sm:p-16">
      <div className="absolute inset-0 bg-grid-white/[0.02] bg-grid" />
      <div className="relative flex flex-col-reverse items-center gap-8 sm:flex-row sm:justify-between">
        <div className="flex flex-col gap-6 text-center sm:text-left">
          <h1 className="text-3xl font-bold tracking-tight text-white sm:text-6xl">
            Clone Factory
            <span className="block text-xl font-normal text-gray-400 sm:text-2xl mt-2">
              AI Character Short-form
            </span>
          </h1>
          <p className="text-base text-gray-300 sm:text-xl max-w-2xl">
            단 한 장의 사진으로 당신만의 AI 캐릭터를 만들고, 무한한 이야기를 펼쳐보세요.
            당신의 목소리를 가진 캐릭터가 음악, 댄스, 연기를 통해 새로운 콘텐츠의 세계를 열어갑니다.
          </p>
        </div>
        <div className="relative">
          <div className="absolute inset-0 bg-gradient-to-br from-purple-600 to-blue-600 opacity-75 blur-2xl" />
          <Image
            src={sopi}  // import한 이미지 사용
            alt="AI Character"
            className="relative rounded-2xl h-[300px] w-[300px] object-cover"
          />
        </div>
      </div>
    </div>
  );
}
