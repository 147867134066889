import Link from "next/link";
import React from "react";

interface Props {
  title: string;
  href: string;
  target?: string;
}

export default function TextLinkButton({ title, href, target }: Props) {
  return (
    <Link
      className="underline text-textLightColor text-base sm:text-lg flex w-full justify-end sm:right-8 top-8 sm:top-10"
      target={target}
      href={href}
    >
      {title}
    </Link>
  );
}
