import Image from "next/image";
import homeIcon from "../../../../public/statics/images/homeIcon.png";

export default function SignInTitle() {
  return (
    <div className="flex flex-col items-center justify-center gap-4 text-center sm:gap-6 bg-black">
      <Image
        src={homeIcon}
        alt="home icon"
        className="h-16 w-16 sm:h-28 sm:w-28"
      />
      <div className="flex flex-col items-center text-center space-y-4 sm:space-y-6">
        <h1 className="text-5xl font-bold tracking-tight sm:text-6xl mt-2">
          <span className="text-white">부캐공장</span>
        </h1>
        <p className="text-lg font-light sm:text-xl text-white">
          AI 캐릭터 숏폼
        </p>
      </div>
    </div>
  );
}