import { useSearchParams, redirect } from "next/navigation";
import { signOut } from "next-auth/react";
import { useEffect } from "react";

const AuthErrorChecker: React.FC = () => {
  const searchParams = useSearchParams();
  const isError = searchParams.get("isError");

  useEffect(() => {
    if (isError) {
      signOut({ callbackUrl: "/auth" });
    }
  }, [isError]);

  return null; // 이 컴포넌트는 UI를 렌더링하지 않습니다.
};

export default AuthErrorChecker;