export const AUTH_TOKEN = "token";
export const SOUND_ONOFF = "sound_onoff";
export const FEEDBACK_DO_NOT_OPEN_AGAIN = "feedback_do_not_open_again";
export const CHAT_GUIDE_MODAL_SHOWED_BEFORE = "chat_guide_modal_showed_before";

export function setItemInLocalStorage(key: string, value: string) {
  if (typeof window !== "undefined") {
    localStorage.setItem(key, value);
  }
}

export function getItemInLocalStorage(key: string): string | null {
  if (typeof window !== "undefined") {
    return localStorage.getItem(key);
  }
  return null;
}

export function removeItemInLocalStorage(key: string) {
  if (typeof window !== "undefined") {
    localStorage.removeItem(key);
  }
}
