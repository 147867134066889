import React from "react";

interface Props {
  title: string;
  onClick: () => void;
  style?: string;
  disabled?: boolean;
}

export default function Button({
  title,
  onClick,
  style = "bg-primaryColor hover:bg-violet-300 ",
  disabled = false,
}: Props) {
  return (
    <button
      className={`h-14 rounded-lg border-[0.8px] border-textColor px-9 text-xl font-extrabold ${style}`}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </button>
  );
}
