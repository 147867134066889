import React from "react";

interface Props {
  title: string;
  description: string;
  className?: string;
}

export default function ConsultationTechniqueItem({ title, description, className }: Props) {
  return (
    <div className={className}>
      <div className="absolute inset-0 bg-gradient-to-br from-violet-600/10 to-blue-600/10 opacity-0 transition-opacity group-hover:opacity-100" />
      <h3 className="mb-3 text-lg font-semibold text-white">{title}</h3>
      <p className="text-sm text-gray-300">{description}</p>
    </div>
  );
}
