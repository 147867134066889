import React from "react";
import advantage1 from "../../../../public/statics/images/advantage1.png";
import advantage2 from "../../../../public/statics/images/advantage2.png";
import advantage3 from "../../../../public/statics/images/advantage3.png";
import advantage4 from "../../../../public/statics/images/advantage4.png";
import AdvantageItem from "./AdvantageItem";

export default function Advantages() {
  const advantages = [
    {
      title: "원스톱 AI 캐릭터 제작",
      description: "사진 한 장으로 완성되는 당신만의 AI 캐릭터. 목소리부터 표정, 제스처까지 모두 커스터마이징 가능합니다."
    },
    {
      title: "프로페셔널한 콘텐츠 제작",
      description: "하몬 서클 방법론과 바이럴 콘텐츠 전략이 적용된 AI 에이전트가 최적화된 콘텐츠를 생성합니다."
    },
    {
      title: "비용 효율적인 운영",
      description: "고가의 강의나 전문 인력 없이도, AI가 최적화된 숏폼 콘텐츠를 제작. AI 자동화로 시간과 비용을 동시에 절약하세요."
    },
    {
      title: "무한한 확장성",
      description: "원하는 주제로 자유롭게 커스터마이징. 이야기, 음악, 댄스 등 다양한 형태의 콘텐츠를 제작할 수 있습니다."
    }  ];


  return (
    <div className="grid gap-6 sm:grid-cols-2">
      {advantages.map((item, index) => (
        <div
          key={index}
          className="group relative overflow-hidden rounded-2xl bg-gradient-to-br from-gray-900 to-black p-8 transition-all hover:scale-[1.02]"
        >
          <div className="absolute inset-0 bg-gradient-to-br from-violet-600/20 to-blue-600/20 opacity-0 transition-opacity group-hover:opacity-100" />
          <h3 className="mb-4 text-xl font-bold text-white">{item.title}</h3>
          <p className="text-gray-300">{item.description}</p>
        </div>
      ))}
    </div>
  );
}