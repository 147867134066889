import React from "react";
import { motion } from "framer-motion";

interface AiTherapistDescItemProps {
  title: string;
  description: string[];
  gradient: string;
}

export default function AiTherapistDescItem({ title, description, gradient }: AiTherapistDescItemProps) {
  return (
    <motion.div
      whileHover={{ scale: 1.02, transition: { duration: 0.2 } }}
      className="group relative"
    >
      <div className={`absolute inset-0 rounded-2xl bg-gradient-to-br ${gradient} blur-sm opacity-60 transition-opacity group-hover:opacity-100`} />
      <div className="relative h-full rounded-2xl bg-black/50 p-6 sm:p-8 backdrop-blur-sm border border-white/10">
        <h3 className="mb-4 text-xl font-bold text-white sm:text-2xl bg-gradient-to-br from-white to-white/80 bg-clip-text">
          {title}
        </h3>
        {description.map((text, index) => (
          <p key={index} className="text-gray-300/90 sm:text-lg">
            {text}
          </p>
        ))}
      </div>
    </motion.div>
  );
}